@use '@shared/util-styling/mixins';

.content-container {
  margin: 0 auto;
  max-width: var(--container-max-width);
  min-width: var(--container-min-width);
  padding-inline: var(--container-spacing-inline);
  width: 100%;

  &--wide {
    padding: 0;
  }

  &__content {
    margin: 0 auto;
    max-width: var(--section-content-max-width);
    position: relative;

    &--wide {
      max-width: 100%;
    }
  }
}
