@use '@shared/util-styling/mixins';

.carousel-arrow-buttons {
  $_this: &;

  @include mixins.button-group-wrapper;

  display: none;
  margin-left: auto;

  @include mixins.breakpoint('small') {
    display: flex;
  }

  @include mixins.device('touch') {
    display: none;
  }

  &__button {
    @include mixins.button;

    background: var(--mdrt-color-backgrounds-bg-secondary-fill);
    border: none;
    height: 24px;
    padding: 0;
    width: 24px;
  }

  &__arrow {
    &--right {
      transform: rotate(180deg);
    }
  }

  &--secondary {
    #{$_this}__button {
      background: var(--mdrt-color-backgrounds-bg-neutral-fill);
    }
  }
}
